import React, { useState, useEffect, useRef } from 'react';
import { useSession } from '../stores/useSession';
import { Link } from 'react-router-dom'
import { Form, Field } from 'react-final-form';
import { useMemberstack } from "@memberstack/react";
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import { OverlayPanel  } from 'primereact/overlaypanel';
import { classNames } from 'primereact/utils';
import Plan from './plan';
import Terms from '../boilerplate/terms';
//import * as api from '../../api/api';
import { uuidv4, getHslFromString } from '../../shared/fn';
import * as cnst from '../../shared/constants';
import './_forms.scss';
import {
    useQueries,
} from '@tanstack/react-query';

const CreateAccountForm = (props) => {
    const { closeDialog } = props;

    const [errorMessage, setErrorMessage] = useState(' ');
    const [formMode, setFormMode] = useState('edit');
    const [accept, setAccept] = useState(false);
    const [disabled] = useSession(false);
    const [displayBasic, setDisplayBasic] = useState(false);
    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const [password, setPassword] = useState(null);
    const [priceId, setPriceId] = useState(null);
    const [defaultPriceId, setDefaultPriceId] = useState(null);
    const [planId] = useState('pln_select-o6l082l');
    const [plans, setPlans] = useState();
    //const [xxx, setXxx] = useState(false);

    //const [state, actions] = useSession();
    const memberstack = useMemberstack();
    const navigate = useNavigate();
    
    const op = useRef(null);

    let cancelRedirect = '/verify?cancel=true';
    let successRedirect = '/verify';

    
    const handlePremiumPlans = (data) => {
        setPlans(data);
        
        const defaultPrice = data.filter(x => x.interval.type.toLowerCase()==="yearly");
        setDefaultPriceId(defaultPrice[0].id);
    }

    const fetchPlans = async () => {
        const allPlans = await memberstack.getPlans();
        const premPlans = allPlans.data.filter(x => x.prices.length > 0);
        const plans = premPlans[0].prices.filter(x=> x.status !== "INACTIVE");
        return plans;
    };
  
    const responses = useQueries({
        queries: [
            { //0
                queryKey: ['get-ms-premium-plans'],
                queryFn: fetchPlans,
                staleTime: 86400000,
                refetchInterval:86400000,
                enabled: !priceId
            }
        ],
    });

    const openTooltip =(e) => {
        op.current.toggle(e);
    }
    
    const dialogFuncMap = {
        'displayBasic': setDisplayBasic
    }

    const onClick = (name) => {
        dialogFuncMap[`${name}`](true);
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    const renderFooter = (name) => {
        return (
            <div>
                <Button label="Close" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" autoFocus/>
            </div>
        );
    }

    useEffect(() => {
        if(responses[0].status==="success") {
            handlePremiumPlans(responses[0].data);
        }
    }, [responses]);

    const validate = (data) => {
        if(data?.firstname)
            localStorage.setItem('__sak_regform_firstname', data.firstname.trim());
        
        if(data?.lastname)
            localStorage.setItem('__sak_regform_lastname', data.lastname.trim());
        
        if(data?.email)
            localStorage.setItem('__sak_regform_email', data.email.trim());

        if(!formMode==='edit') return;

        let errors = {};

        if (!data.firstname) {
            errors.firstname = ' required';
        }

        if (!data.lastname) {
            errors.lastname = ' required';
        }

        // if (!data.username) {
        //     errors.username = ' required.';
        // }

        if (!data.email) {
            errors.email = ' required';
        }
        else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
            errors.email = 'Invalid email address. E.g. example@email.com';
        }

        if (!data.password) {
            errors.password = ' required';
        }

        if (! /^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,20}$/gm.test(data.password)) {
            errors.password = ' invalid';
        }
        else {
            setPassword(data.password);
        }

        if (!data.accept) {
            errors.accept = ' required';
        }

        return errors;
    };

    const onSubmit = async (data, form) => {              
        if(formMode==='edit'){
            setFormMode('review');
            return;
        }

        setSpinnerVisible(true);

        let errors = false;        

        try {
            const uniqueId = uuidv4();
            const str = data.firstname.trim()+data.email.trim();
            const hsl = getHslFromString(str);
            const selectedPriceId = priceId ?? defaultPriceId;

            const msMember = await memberstack.signupMemberEmailPassword({
                email: data.email.trim(),
                password: data.password.trim(),
                customFields: {
                    firstname: data.firstname.trim(),
                    lastname: data.lastname.trim(),
                    username: data.username?.trim(),
                    uniqueid: uniqueId,
                    hsl: hsl,
                    blocked: '0',
                    submitfeedback: '1',
                    dbmbrid: '0'
                },
                plans: (selectedPriceId) ? null : [{planId: planId}]
            })
    
            if(msMember.data.member) {
                closeDialog(false);
                //const member = JSON.stringify(msMember.data.member);

                console.log('msMember.data', msMember.data);
                
                if(selectedPriceId) {
                    //if user abandons checkout in stripe, this url will be invoked and redirect to /verify
                    window.history.replaceState(null, "", `${cancelRedirect}`);

                    await memberstack.purchasePlansWithCheckout({
                        priceId: selectedPriceId,
                        autoRedirect: true,     // false returns data.url
                        cancelRedirect: `${cancelRedirect}`,
                        successRedirect: `${successRedirect}`
                    });
                }
                else {
                    navigate("/cancel", { state: { member: msMember.data }, replace: true });
                }
            }            
        } 
        catch(err) {
            setErrorMessage(err.message.replace('The provided email is already taken.', 'This email address already has an account associated with it.  Please log in using this email address.'));
            setSpinnerVisible(false);
            setFormMode('edit');

            if(err) {
                errors = true;
            }

           if(!errors) {
                closeDialog();
           }
                         
        }

        form.restart();
    };

    const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
    const passwordFooter = (
        <>
            <p className="mt-2 mb-1">Password requirements</p>
            <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: '1.5' }}>
                <li>At least one lowercase character</li>
                <li>At least one uppercase character</li>
                <li>At least one numeric</li>
                <li>Minimum 8 characters</li>
                <li>Maximum 20 characters</li>
            </ul>
        </>
    );

    const onPriceClick =(priceId) => {
        setPriceId(priceId);
    }

    return (
        <div>
            <Form 
                onSubmit={onSubmit}
                initialValues={{
                    firstname: localStorage.getItem('__sak_regform_firstname') ?? '', 
                    lastname:  localStorage.getItem('__sak_regform_lastname') ?? '',
                    //username: (customFields && customFields.username) ?? localStorage.getItem('__sak_regform_username') ?? '',  
                    email: localStorage.getItem('__sak_regform_email') ?? '', 
                    password: password,
                    plan: planId,
                    price: priceId,
                    accept: accept 
                }} 
                validate={validate} 
                render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} className={`${formMode}-mode p-fluid pt-0`}>
                        <ProgressSpinner 
                            style={{position: 'absolute', top: '50%', width: '50%', height: '10%', transform: 'translate(-50%, -50%)'}}
                            className={`${spinnerVisible ? '' : 'hidden'}`}
                        />

                        <div className={`form-message ${(formMode==='edit' && errorMessage) ? '' : 'hidden'}`}>                            
                            {errorMessage}
                        </div>

                        <div className={`form-message ${formMode==='review' ? 'review-message' : 'hidden'}`}>
                            Please review and click '{cnst.LBL_CREATE_ACCOUNT}' to create your membership.
                        </div>

                        <div className={`flex justify-content-center gap-3 ${formMode==='review' ? 'mt-0' : 'm-0'}`}>
                            <Field name="firstname" render={({ input, meta }) => (
                                <div className="field firstname">
                                    <span className="">
                                        <label 
                                            htmlFor="firstname" 
                                            className={`form-label ${classNames({ 'p-error': isFormFieldValid(meta) })}`}
                                            style={{}}
                                        >
                                            {cnst.LBL_FIRST_NAME}: <span className='asterisk'>*</span>
                                        </label>&nbsp;
                                        <InputText 
                                            id="firstname" 
                                            {...input} 
                                            autoFocus={ true }
                                            disabled={false}
                                            readOnly={formMode==='review'}
                                            className={classNames({ 'p-invalid': isFormFieldValid(meta) })}
                                            minLength={2}
                                            name='firstname'
                                            style={{width: '20rem'}}
                                        />
                                    </span>
                                </div>
                            )} />
                        </div>
                        
                        <div className={`flex justify-content-center gap-3 ${formMode==='review' ? 'mt-0' : 'm-0'}`}>
                            <Field name="lastname" render={({ input, meta }) => (
                                <div className="field lastname">
                                    <span className="">
                                        <label htmlFor="lastname" className={`form-label ${classNames({ 'p-error': isFormFieldValid(meta) })}`}>
                                            {cnst.LBL_LAST_NAME}: <span className='asterisk'>*</span>
                                        </label>&nbsp;
                                        <InputText 
                                            id="lastname" 
                                            {...input}
                                            disabled={false}
                                            readOnly={formMode==='review'}
                                            className={classNames({ 'p-invalid': isFormFieldValid(meta) })}
                                            minLength={2}
                                            data-ms-member='lastname'
                                            style={{width: '20rem'}}
                                        />
                                    </span>
                                </div>
                            )} />
                        </div>

                        <div className={`flex justify-content-center gap-3 ${formMode==='review' ? 'mt-0' : 'm-0'}`}>
                            <Field name="email" 
                                render={({ input, meta }) => (
                                <div className="field email">
                                    <span className="">
                                        <label htmlFor="email" className={`form-label ${classNames({ 'p-error': isFormFieldValid(meta) })}`}>
                                            {cnst.LBL_EMAIL}: <span className='asterisk'>*</span>
                                        </label>&nbsp;
                                        <InputText 
                                            id="email" 
                                            {...input}
                                            disabled={false}
                                            readOnly={formMode==='review'}
                                            className={classNames({ 'p-invalid': isFormFieldValid(meta) })}
                                            style={{width: '20rem'}}
                                        />                                        
                                    </span>
                                </div>
                            )} />
                        </div>

                        <div className={`flex justify-content-center gap-3 ${formMode==='review' ? 'mt-0' : 'm-0'}`}>
                            <Field name="password" 
                                render={({ input, meta }) => (
                                <div className={`field password`}>
                                    <span className={`${formMode==='edit' ? 'p-input-icon-right flex align-items-center' : 'flex'}`}>
                                        <label htmlFor="password" 
                                            className={`form-label mt-2 ${classNames({ 'p-error': isFormFieldValid(meta) })}`}
                                        >
                                            {cnst.LBL_PASSWORD}: <span className='asterisk'>*</span>
                                            
                                        </label>
                                        <span className={`${formMode==='review' ? '' : 'hidden'}`} style={{paddingLeft: '0rem'}}>
                                            <InputText 
                                                id="review_password" 
                                                {...input}
                                                disabled={false}
                                                readOnly={formMode==='review'}
                                                className={classNames({ 'p-invalid': isFormFieldValid(meta) })}
                                                minLength={2}
                                                style={{width: '9rem', position: 'relative',  left:'.5rem'}}
                                            />
                                        </span>

                                        <Password 
                                            inputId="password" 
                                            {...input}
                                            toggleMask = { true }
                                            disabled={false}
                                            readOnly = {formMode==='review'}
                                            footer={passwordFooter}
                                            feedback={true}
                                            maxLength={20}
                                            mediumRegex
                                            style = {{width: '20rem', visibility: formMode==='review' ? 'hidden' : 'visible'}}
                                            panelStyle={{background:'#37393f'}}
                                        />

                                        <i className="pi pi-info-circle hand" 
                                            data-pr-tooltip={passwordFooter.toString()}
                                            data-pr-position="right" 
                                            onClick={(e) => openTooltip(e)}
                                            style={{ marginLeft: '.5rem', position: 'absolute', left: '28rem', visibility: formMode==='review' ? 'hidden' : 'visible' }}
                                        />

                                        <OverlayPanel ref={op} >
                                            <div className={`tooltip-content`} style={{lineHeight: '1.25rem', margin: '0'}}>
                                                {passwordFooter}
                                            </div>
                                        </OverlayPanel>
                                    </span>
                                </div>
                            )} />
                        </div>
                        
                        <div className={`flex justify-content-center gap-3 mt-0`}>
                            <div style={{width: (plans?.length<=2 || formMode==='review') ? '75%' : '100%'}}>
                                <label className={`${formMode==='review' ? '' : 'hidden'}`}></label>
                                <Plan 
                                    plans={plans} 
                                    selectedPrice={priceId ?? defaultPriceId}
                                    formMode={formMode} 
                                    onPriceClick={onPriceClick}
                                />
                            </div>                            
                        </div>

                        <div style={{display: 'none'}}>
                            <Field name="username" render={({ input, meta }) => (
                                <div className="field">
                                    <span className="">
                                        <i className="pi pi-user" />
                                        <label htmlFor="username" className={`${classNames({ 'p-error': isFormFieldValid(meta) })}`}>
                                            {cnst.LBL_USER_NAME} : <span className='asterisk'>*</span>
                                            {/* <span className={`form-error-container`}>{ getFormErrorMessage(meta)}</span> */}
                                        </label>
                                        <InputText 
                                            id="username" 
                                            {...input}
                                            disabled={false}
                                            readOnly={formMode==='review'}
                                            className={classNames({ 'p-invalid': isFormFieldValid(meta) })} 
                                            data-ms-member='username'
                                            minLength={2}
                                            maxLength={25}
                                        />
                                    </span>
                                </div>
                            )} />
                        </div>

                        <div className={`flex justify-content-center ${formMode==='edit' ? 'pt-3 pb-3' : 'pt-0 pb-0'}`}>
                            <Field name="accept" type="checkbox" render={({ input, meta }) => (
                                <div className={`field-checkbox ${formMode==='review' ? 'hidden' : ''}`}>
                                    <Checkbox 
                                        inputId="accept"
                                        onChange={e => setAccept(e.checked)}
                                        {...input} 
                                        className={classNames({ 'p-invalid': isFormFieldValid(meta) })}
                                        disabled={formMode==='review'}
                                        //checked={accept}
                                    />
                                    &nbsp;&nbsp;
                                    {cnst.LBL_READ_TERMS}&nbsp;<Link onClick={() => onClick('displayBasic')} className={`link`}>{cnst.LBL_TERMS_CONDITIONS}</Link>
                                    <label 
                                        htmlFor="accept" 
                                        className={classNames({ 'p-error': isFormFieldValid(meta) })}>
                                    </label>
                                </div>
                            )} />
                        </div>


                        <div className={`faux-form-footer`}>
                            <Button 
                                type="button" 
                                label="Back" 
                                onClick={(e) => setFormMode('edit')} 
                                className="mt-0 p-button-secondary"
                                disabled = {formMode==='edit' ? false : !disabled}
                                visible={formMode==='edit' ? false : true}
                            />
                            &nbsp;&nbsp;
                            <Button 
                                type="button" 
                                label={"Cancel"} 
                                onClick={(e) => closeDialog(e)}
                                className="mt-0 p-button-secondary"
                                disabled = {formMode==='edit' ? false : !disabled}
                            />   
                            <Button 
                                type="submit" 
                                label={formMode==='edit' ? "Review" : cnst.LBL_CREATE_ACCOUNT} 
                                className="mt-0 p-button-primary" 
                                disabled = {(formMode==='edit' ? false : formMode==='error')}
                            />
                        </div>                        
                    </form>
                    
            )} /> 

            <Dialog 
                header={cnst.LBL_TERMS_CONDITIONS}
                visible={displayBasic} 
                style={{ width: '60vw' }} 
                footer={renderFooter('displayBasic')} 
                onHide={() => onHide('displayBasic')}
            >
                <div><Terms /></div>
            </Dialog>

        </div>

    );
  }

  export default CreateAccountForm