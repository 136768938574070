import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSession } from './components/stores/useSession';
import { useAppState } from './components/stores/useAppState';
import { Splitter, SplitterPanel } from 'primereact/splitter'; 
import { Outlet, useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { Dialog } from 'primereact/dialog';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Button } from 'primereact/button';
import Header from './layout/header';
import Menu from "./layout/menu";
import UpdateAuthForm from './components/forms/updateAccount';
import { emptyCache, isAdmin, delay, uuidv4, emptyGuid  } from './shared/fn';
import * as api from './api/api';
import './styles/scss/app.scss';
import {
    useQueries,
} from '@tanstack/react-query';

const App = (props) => {
    const { memberstack } = props;
    const [member, setMember] = useState();
    const [showTokenDlg, setShowTokenDlg] = useState(false);
    const [showRefreshDlg, setShowRefreshDlg] = useState(false);
    const [showProfileUpdDlg, setShowProfileUpdDlg] = useState(false);
    const [betaMode, setBetaMode] = useState(false);
    const [appSettings, setAppSettings] = useState(null);
    //const [xxx, setXxx] = useState();

    const [state, actions] = useSession();
    const [appState, appActions] = useAppState();
    const navigate = useNavigate();
    const location = useLocation();
    const isAppRoot = location.pathname === '/';
    const pathName = location.pathname.replace('/','')
    const isLoginRedirectPage = location.pathname.replace('/','') === sessionStorage.getItem('__sak:path') ?? 'dashboard';
    const currentMemberRef=useRef(null);
    const isListeningRef=useRef(true);
    const beenHereRef=useRef(false);
    const appSettingRef=useRef(false);
    const [searchParams] = useSearchParams();
    //console.log('forceRefetch', searchParams.get("forceRefetch")==='true')
    //const pathRef=useRef();

    const setUserSettings = (val) => {
        console.clear();

        if(val === "0") return;

        const theRoot = document.getElementById("root");
        theRoot.addEventListener("contextmenu", function(event) {
            event.preventDefault();
        });
    }

    const getAppSettings = async() => {
        const data=await api.getAppSettings();
        return  data;
    }

    const handleAppSettings = (data) => {
        setAppSettings(data);
    }

    const responses = useQueries({
        queries: [
            {
               queryKey: ['get-app-settings'],
               queryFn: getAppSettings,
               staleTime: 86400000,
               refetchInterval:86400000,
               enabled: appSettings === null,
            }           
        ],
    })

    // const updateMember = useCallback(async(member, updatePlan = true)=> {
    //     if(!member) return;

    //     await api.updateMember(member);

    //     if(updatePlan) {
    //         await api.updateMemberPlan(member);
    //     }        

    // }, []);

    const syncUserSession = useCallback(async(member) => {
        if(!member) return;

        const session = await api.getSession(member.customFields.uniqueid);
        actions.authchange(member, session.data.data[0]?.sessionid);
    }, [actions]);

    const onCookieAckClick = () => {
        setShowTokenDlg(false);
        localStorage.setItem('__sak:0r4kcTsUHY', new Date().getTime());
    }

    const renderCookieAckDialogFooter = (name) => {
        return (
            <div>
                <Button label="Accept" icon="pi pi-check" onClick={() => onCookieAckClick(name)} autoFocus />
            </div>
        );
    }

    const onRefreshAckClick =()=> {
        setShowRefreshDlg(false);
        localStorage.setItem('__sak:MVjuSavEw0', false);
    }

    const refreshAckDialogFooter = (name) => {
        return (
            <div>
                <Button label="OK" icon="pi pi-check" onClick={() => onRefreshAckClick(name)} autoFocus />
            </div>
        );
    }

    const cookieAck = () => {
        const ack = localStorage.getItem('__sak:0r4kcTsUHY');

        if(!ack) {
            setShowTokenDlg(true);
        }
    }

    const handleDialogClose = (e) => { 
        setShowProfileUpdDlg(false);
    }

    // const refreshAck = useCallback(() => {
    //     const cookieAck = localStorage.getItem('__sak:0r4kcTsUHY');

    //     if(cookieAck) {
    //         emptyCache();
    //     }
    // },[]);

    const handleAuthChange = useCallback(async(data) => {
        let tokenId='';

        if(data) {
            if(beenHereRef.current) return;
            beenHereRef.current = true;

            const dbMember = await api.getMember(data.id);
            if(dbMember?.data.data.length===1) {
                const res = await api.createUserSession(data, tokenId);
                actions.authchange(data, res.data.response);
                setMember(data);
                return res.data.response;
            }
        }
    }, [actions]);

    const expireUserSession = useCallback(async(msId) => {
        const sessionId = state.sessionId;

        actions.authchange({}, null);
        await api.expireUserSession(sessionId);
    }, [actions, state.sessionId]);

    const trackEvent = useCallback(async()=>{

        if(process.env.NODE_ENV ==='development') return;

        //don't track views for ms protected pages and user is not logged in
        if((!isAppRoot && pathName !== 'dashboard' && !state.sessionId)) return;

        await delay(1000);

        const emptyUuid = emptyGuid()
        if(state.sessionId) {
            api.trackEvent(pathName, state.sessionId, emptyUuid);
        }
        else {
            const trackUuid = localStorage.getItem('__sak:de5f44x2x') ?? uuidv4();
            localStorage.setItem('__sak:de5f44x2x', trackUuid);
            api.trackEvent(pathName, emptyUuid, trackUuid);
        }
    }, [pathName, state.sessionId, isAppRoot]);


    const getCurrentMember = useCallback(async(isLogin) => {
        //let isUpdated = false;

        await memberstack.getCurrentMember()
            .then(({ data }) => {
                if(data) {
                    data.auth.isLogin=isLogin;
                    setMember(data);
                    
                    if(data.planConnections.length>0) {
                        //see if user is an invitee who must update their profile
                        if(data.planConnections?.find(x => x.type.toLowerCase()==="free")?.active && !data.customFields.uniqueid) {
                            setShowProfileUpdDlg(true);
                        }
                    }

                    // if(data.permissions.length>0 && !isAdmin(data.permissions[0])) {
                    //     updateMember(data, true);
                    // }

                    if(isLogin) {
                        handleAuthChange(data);                        
                        
                        if (data.permissions.length===0 && data.planConnections.length===0) {  //user closed stripe without clicking back or completing c/o
                            navigate('/verify', {state: {replace: true }})
                        }
                    }
                    else {
                        syncUserSession(data);
                    }

                    currentMemberRef.current = data;
                    return data;
                }
            })
            .catch();

    }, [memberstack, syncUserSession, handleAuthChange, navigate]);

    useEffect(() => {
        trackEvent();

        //console.log('member', member)
        if(responses[0].status==="success") { 
            handleAppSettings(responses[0].data);
        }

        let isLogin=false;

        if(location.state?.isLogin) {
            isLogin = location.state?.isLogin;
            window.history.replaceState({}, '');
            location.state.isLogin = false;
        }
        
        if(location.state?.isLogout) {
            expireUserSession(location.state?.memberId);
            window.history.replaceState({}, '');
            location.state.isLogout = null;
            currentMemberRef.current = null;
            beenHereRef.current = false;
            setMember(null);
        }

        const listener = memberstack.onAuthChange(data => {
            if(!data) {     //signout
                isListeningRef.current = true;
                handleAuthChange(null);
                setMember(null);
                currentMemberRef.current = null;
                beenHereRef.current = false;
                return;
            }
        });

        listener.unsubscribe();

        if(searchParams.get("forceRefetch")==='true'){
            getCurrentMember(isLogin);
            window.history.replaceState(null, '', window.location.pathname);
            navigate('/profile', {state: {replace: true }})
        };

        if(!member) {
            getCurrentMember(isLogin);
        }

    }, [
        member
        , memberstack 
        , actions
        , location.pathname
        , isAppRoot
        , getCurrentMember
        , responses
        , isLoginRedirectPage
        , handleAuthChange
        , state
        , location
        , expireUserSession
        , trackEvent
        , searchParams
        , navigate
        ]
    );

    useEffect(() => {
        cookieAck();

        if(appSettings) {
            if(appSettingRef.current) return;
            appSettingRef.current = true;

            const versionId = responses[0].data.filter(x => x.settingname==='current_version')[0].settingvalue;
            const rightClick = responses[0].data.filter(x => x.settingname==='disable_right_click')[0].settingvalue;
            const maintMode = responses[0].data.filter(x => x.settingname==='maint_mode')[0].settingvalue==='1';
            const dmEnabled = responses[0].data.filter(x => x.settingname==='dm_enabled')[0].settingvalue==='1';
            const beta_mode = responses[0].data.filter(x => x.settingname==='beta_mode')[0].settingvalue==='1';
            const latencyThreshold = responses[0].data.filter(x => x.settingname==='latency_threshold')[0].settingvalue;

            setBetaMode(beta_mode);

            if(state.data?.permissions) {
                const admin = isAdmin(state.data.permissions[0]);
                if(!admin) {
                    setUserSettings(rightClick);
                }
            }
            
            appActions.setMaintMode(maintMode);
            appActions.setDmEnabled(dmEnabled);
            appActions.setVersion(versionId);
            appActions.setLatencyThreshold(latencyThreshold);
            
            const version = `{"current": "${versionId}"}`;
            const jsonStr = localStorage.getItem('version') ?? version;
            const userVersion = JSON.parse(jsonStr);

            localStorage.setItem('version', version);

            if(parseInt(userVersion.current) < versionId) {                
                emptyCache();
            }
        }

    }, [appActions, isAppRoot, responses, member, state.data?.permissions, betaMode, navigate, appSettings]);

    if(member?.customFields.blocked==='1') return <>757a19a4-c724-4677-b8f7-b7f86c4882cd</>;

    if(appState.maintMode) return <>Down for maintenance</>;

    return (
        <main 
            className={`${process.env.NODE_ENV ==='development' ? 'dev' : ''} ${member ? 'auth' : 'anon'}`}>                
            <Splitter style={{height: `100vh`, width: `100vw`}} className={`width-${window.innerWidth} main-splitter`}>
                <SplitterPanel className={`sidebar sidebar-left ${(isAppRoot) ? 'hidden' : ''}`} size={10} minSize={10}>
                    <Menu 
                        permissions = {state.data?.permissions && state.data?.permissions[0]} 
                    />
                </SplitterPanel>
                <SplitterPanel size={90} minSize={90} className={`content`}>
                    <Splitter layout="vertical">
                        <SplitterPanel size={10}>
                            <Header
                                member = { member }
                                memberstack = { memberstack }
                                betaMode = { betaMode }
                            />
                        </SplitterPanel>
                        
                        <SplitterPanel size={90} minSize={90} className={`vertical-container`}>
                            <Splitter>
                                <SplitterPanel
                                    className={``}
                                    size={100}
                                    minSize={100} 
                                    style={{overflowY: 'auto', overflowX: 'hidden'}}
                                >                                    
                                    <ScrollPanel style={{ height: '100vh' }} className={`outlet-wrapper ${isAppRoot ? 'app-root ' : !member ? 'anon ' : pathName}  vis--border`}>
                                        <Outlet />
                                    </ScrollPanel>                                
                                </SplitterPanel>
                            </Splitter>
                        </SplitterPanel>
                    </Splitter>
                </SplitterPanel>
            </Splitter>

            <Dialog 
                header="Cookie Consent" 
                visible={showTokenDlg}
                footer={renderCookieAckDialogFooter('showTokenDlg')}
                className={`ack-dlg`}
                modal
                resizable={false}
                draggable={false}
                closeOnEscape={false}
                closable={false}
                blockScroll={true}
                maskClassName={``}
            >
                <div className={`flex`}>
                    <span style={{padding:'1rem'}}>
                        <i className="pi pi-info-circle" style={{fontSize: '3rem', color: '#6fbaed', margin: '2rem 0'}}></i>
                    </span>
                    <span style={{fontSize: '1.1rem',padding:'1.5rem', lineHeight:'25px'}}>
                        We use cookies and similar methods to recognize visitors and remember their preferences. 
                        We also use them to measure performance and site traffic. We do not collect personally identifying information.
                        By clicking 'Accept,' you consent to the use of these methods by us and third parties. You can review our use of cookies
                        by visiting our Privacy policy.
                    </span>
                </div>
            </Dialog>

            <Dialog 
                header="New Version Published" 
                visible={showRefreshDlg}
                footer={refreshAckDialogFooter('showRefreshDlg')}
                className={`ack-dlg`}
                modal
                resizable={false}
                draggable={false}
                closeOnEscape={false}
                closable={false}
                blockScroll={true}
                maskClassName={``}
            >
                <div className={`flex`}>
                    <span style={{padding:'1rem'}}>
                        <i className="pi pi-info-circle" style={{fontSize: '3rem', color: '#6fbaed', margin: '.5rem 0'}}></i>
                    </span>
                    <span style={{fontSize: '1.1rem',padding:'1.5rem', lineHeight:'25px'}}>
                        We have published a new version of the site since your last visit. We recommend clearing your browser cache and performing a hard
                        reload. 
                    </span>
                </div>
            </Dialog>

            <Dialog 
                header="Profile Update Required"
                visible={showProfileUpdDlg}
                className={`auth-upd-dlg`}
                modal
                resizable={false}
                draggable={false}
                closeOnEscape={false}
                closable={false}
                blockScroll={true}
                maskClassName={``}
                style={{width: '37%'}}
                
            >
                <div className={`flex`}>
                    <UpdateAuthForm member={member} closeDialog={handleDialogClose} />
                </div>
            </Dialog>
        </main>
    );
}


export default App;