    import * as cnst from './constants';
    //import $ from 'jquery';

    export const msInHour=3600000;
    export const tbars=100000000;
    export const rateDivisor=100000000;
    export const apyMulti=100;

    export const toHbar = (tinybars) => {
        const hbars = tinybars / tbars;
        return hbars;
    }

    export const formatHbar = (tinybars) => {
        var val = toHbar(tinybars);
        val = formatFloat(val);
        return formatHbarLabel(val);
    }

    export const formatHbarLabel = (val) => {
        return val + cnst.LBL_HBAR_SYMBOL;
    }

    export function formatInt(val) {
        return parseInt(val).toLocaleString();
    }

    export function formatToDecimal(val, precision) {
        if(val===null) return;
        var fixed = parseFloat(val.toFixed(precision))
        return fixed;
    }

    export function formatFloat(val) {
        const float = parseFloat(val).toLocaleString();
        return float;
    }

    export function formatMoney(val) {
        if(val===null) return;
        
        const retVal = val.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        return retVal;
    }

    export const formatPct = (val, precision) => {
        return val + '%';
    }

    //takes an array and returns the same array
    export function formatData(data) {
        data.forEach(function(rec, idx){
            var objStr = '{';
            var ctr = 0;
            for (const [key, value] of Object.entries(rec)) {
                const formatted = formatDataValue(key, value);
                if(ctr > 0) {
                    objStr += ', ';
                }
                objStr += '"' + key + '": "' + formatted + '"';
                ctr+=1;
            }

            objStr +=  '}';
            this[idx] = JSON.parse(objStr);

        }, data);

        return data;
    }

    function formatDataValue(key, value) {
        if(key.includes("timestamp")) {
            value = tsToDate(value);
        }
        if(key.includes("amount")) {
            value = formatHbar(value);
        }
        if (typeof value == "boolean") {
            value = value ? cnst.LBL_TRUE_VALUE : cnst.LBL_FALSE_VALUE;
        }
        
        value = (value===null) ? "" : value.toString();
        return value;
    }

    export function isAdmin(permissions) {
        return permissions==='9703094d-7ef3-4293-994b-4aff487b5add';
    }

    export function emailRegex() {
        var pattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
        return pattern;
    }

    export function getNoRestrictionsPermissions() {
        return ["user:all", "public:restricted", "contributor:all", "admin"]
    }

    export function getAdminPermissions() {
        return ["9703094d-7ef3-4293-994b-4aff487b5add"]
    }

    export function getFullAccessPermissions() {
        return ["f02bab43-194c-49a2-8cc3-e231a330f472", "916ea8dc-2799-4fe6-9b45-0cbf043a0f08", "9703094d-7ef3-4293-994b-4aff487b5add"]
    }

    export function memberHasPlan(member) {
        if(!member) return false;

        const memberHasPlan = member?.planConnections.length > 0;
        return memberHasPlan;
    }

    export const memberIncomplete = (member) => {
        //console.log(member);
        if(!member || (member?.permissions?.length===0)) {
            return true
        };
        return false;
    }


    export function getBreakpoints() {
        return { '960px': '75vw', '640px': '100vw' }
    }

    export function getSubDirectory() {
        const start = (window.location.toString().lastIndexOf('/')) + 1;
        const end = window.location.toString().length;
        const subdir = window.location.toString().substring(start, end).replace('#', '');
        return subdir;
    }

    export function calcPercent(a, b) {
        if(!a || !b) return;

        const num=a.toString().replaceAll(',','');
        const denom=b.toString().replaceAll(',','');

        return Math.round((num/denom + Number.EPSILON) * 100).toFixed(2);
    }

    export const getMetadataString = (hexx) => {
        let hex = hexx.toString() //force conversion
        hex = hex.split('\\x')[1]
        let str = ''
        for (let i = 0; i < hex.length; i += 2)
        str += String.fromCharCode(parseInt(hex.substr(i, 2), 16))
        return str
    }

    export const groupArrayBy = function(arr, key) {
        return arr.reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
        }, {});
    };

    export const groupArray = function(array, arrayProps) {
        //arrayProps = ['sector', 'geolocation']
        const grouped = Object.values(array.reduce((r, o) => {
            const key = arrayProps.map(k => o[k]).join('|');
            (r[key] ??= []).push(o);
            return r;
        }, {}));

        return grouped;
    }

    export const _groupArrBy = (array, f) => {
        var groups = {};
        array.forEach( function(o)
        {
            var group = JSON.stringify( f(o) );
            groups[group] = groups[group] || [];
            groups[group].push( o );  
        });

        return Object.keys(groups).map( function( group ) {
            return groups[group]; 
        })

    }

    /* --- Date functions ---*/

    export const getTimestamp = (dt) => {
        if(dt){
            return new Date(dt).getTime()*1000000;
        } else
            return new Date().getTime()*1000000;
    }

    export function tsToDate(ts) {
        if(!ts) return;

        const dt = new Date(parseInt(ts.split('.')[0] + '000'));
        return formatDate(dt);
    }

    export const formatDate = (dt) => {
        let tempDt = new Date(dt);
        tempDt = new Date(tempDt.getTime() + Math.abs(tempDt.getTimezoneOffset()*60000))
        if(tempDt==="Invalid Date") return "n/a";

        var arr = tempDt.toString().split(' ');
        return `${arr[1]} ${arr[2]}, ${arr[3]}`;
    }

    export const nanoToLocalDate = (nanoseconds) => {
        let temp = new Date(nanoseconds / 1000000)
        var tempDt = new Date(temp).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"});
        if(tempDt==="Invalid Date") return "n/a";

        //return tempDt;
        var arr = tempDt.split(',')
        return arr[1].replace(" ","") + ',' + arr[2];
    }

    export const nowToNano = () => {
        return new Date().getTime() * 1000000;
    }

    export const nowToSeconds = () => {
        return Math.round(new Date().getTime()/1000);
    }

    export const utcDateToNano = (utcDate) => {
        return new Date(utcDate).getTime() * 1000000;
    }

    export const secondsToNano = (secs) => {
        return secs * 1000000000;
    }

    export const secondsToLocalDate = (secs) => {
        const nano = secondsToNano(secs);
        return nanoToLocalDate(nano);
    }

    export function calendarDateToUtcDate(dt) {
        return Date.UTC(dt.getFullYear(), dt.getMonth(), dt.getDate());
    }

    export function getDateParts(dt) {
        const arr = [];
            arr.push('day', dt.getDate());
            arr.push('month', dt.getMonth());
            arr.push('year', dt.getFullYear());
    }

    export function getHoursAgoTs(hrsAgo) {
        if(!hrsAgo) hrsAgo = 24;

        var ts = new Date(getUtcDate() - (hrsAgo * 60 * 60 * 1000));
        return ts.getTime();
    }

    export function getUTCEndOfDay() {
        var end = new Date();
        const eod = end.setUTCHours(23,59,59,999);
        return eod.toUTCString();
    }

    export function getUtcDateStr() {
        return new Date(new Date().toUTCString());
    }

    export function getDaysInMonth(month, year) {
        return new Date(year, month, 0).getDate();
    }

    //leave ts param empty to drop timestamp
    export function getUtcDate(ts) {
        const a = new Date();
        let utcDate;
        if(!ts)
            utcDate = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
        else {
            var dt = getUtcCalendarDate();
            utcDate = Date.parse(dt);
        }

        return utcDate;
    }

    export function getUtcYear(dt) {
        const d = new Date(dt)
        return d.getUTCFullYear();
    }

    export function getUtcMonth(dt) {
        let month = new Date(dt).getUTCMonth()+1;
        return month;
    }

    export function getUtcDay(dt) {
        let day = new Date(dt).getUTCDate();
        return day;
    }

    export function daysDiff(startDt, endDt) {
        const oneDay = 1000 * 60 * 60 * 24;
    
        const start = Date.UTC(endDt.getFullYear(), endDt.getMonth(), endDt.getDate());
        const end = Date.UTC(startDt.getFullYear(), startDt.getMonth(), startDt.getDate());

        return (start - end) / oneDay;
    }

    export function getUtcCalendarDate() {
        return new Date().toISOString();
    }

    export function getUtcEpochDate() {
        var now = getUtcDateStr();
        return Math.floor(now/8.64e7);
    }

    export function getOperableEpoch() {
        var days = getUtcEpochDate();
        return days - 1;
    }

    export const getUtcDateMidnight = (year, month, day) => {
        if(!year) {
            year=getUtcYear(getUtcCalendarDate());
            month=getUtcMonth(getUtcCalendarDate());
            day=getUtcDay(getUtcCalendarDate());
        }
        const dt = new Date(`${year}/${month}/${day}`).setUTCHours(0,0,0,0);
        return dt;
    }

    export const daysToHours = (days) => {
        return days * 24;
    }

    export function secondsPerDay() {
        return 86400
    }

    export const hoursToMs=(hours) => {
        return hours * msInHour;
    }

    export const daysAgoToUtcDate = (daysAgo) => {
        var d = new Date(getUtcCalendarDate());
        var daysAgoDt = d.setDate(d.getDate() - daysAgo) - daysAgo;
        return daysAgoDt;
    }

    export const mapFilterOption = (val) => {
        const n = parseInt(val);
        let temp;
        if(!isNaN(n)) {
            temp = daysAgoToUtcDate(n)
        }
        else {
            if(val==='YTD') {
                temp=getUtcDateMidnight(getUtcYear(getUtcCalendarDate()), 1, 1);
            }
            else {
                temp=getUtcDateMidnight(2019,9,16);
            }
        }

        const opt = temp+'000000'
        return opt;
    }

    export const msToNextHour = (interval) => {
        return interval - new Date().getTime() % interval;
    }

    export const queryStaleTime = (interval) => {
        return msToNextHour(interval) + 150000;
    }

    /* --- End date functions ---*/

    /* --- Start utility functions ---*/

    export const objectIsEmpty = (obj)=> {
        if(!obj) return true;
        return Object.keys(obj).length === 0;
    }

    export const padStringEnd = (str, len, char) => {
        return str.padEnd(len, "0");

    }

    export const calcTxns_ = (arr) => {
        return arr.map(function(v) { return v[1] })         // second value of each
            .reduce(function(a,b) { 
            return a + b;
        });      // sum
    }

    export const averageArray = array => array.reduce((a, b) => a + b) / array.length;

    export function pxToVw(value) {
        var w = window,
        d = document,
        e = d.documentElement,
        g = d.getElementsByTagName('body')[0],
        x = w.innerWidth || e.clientWidth || g.clientWidth;
    
        var result = (100*value)/x;
        document.getElementById("result_px_vw").innerHTML = result;
        return result;
    }

    export function pxToVh(value) {
        var w = window,
        d = document,
        e = d.documentElement,
        g = d.getElementsByTagName('body')[0],
        y = w.innerHeight|| e.clientHeight|| g.clientHeight;

        var result = (100*value)/y;
        document.getElementById("result_px_vh").innerHTML = result;
        return result;
    }

    export function vwToPx(value) {
        var w = window,
        d = document,
        e = d.documentElement,
        g = d.getElementsByTagName('body')[0],
        x = w.innerWidth || e.clientWidth || g.clientWidth;

        var result = (x*value)/100;
        return result;
    }

    export function vhToPx(value) {
        var w = window,
        d = document,
        e = d.documentElement,
        g = d.getElementsByTagName('body')[0],
        y = w.innerHeight|| e.clientHeight|| g.clientHeight;

        var result = (y*value)/100;
        return result;
    }

    export function getElementsByClass(cls) {
        return document.getElementsByClassName(cls);
    }
    export function getElementById(id) {
        return document.getElementById(id);
    }
    export function getElementsByRole(role) {
        return document.querySelectorAll(`[role=${role}`)
    }

    export function toggleSidebar(cls) {
        var elems = getElementsByClass(cls);
        for(var i = 0; i < elems.length; i++){
            if (elems[i].style.display === "none") {
                elems[i].style.display = "inline-flex";
            } else {
                elems[i].style.display = "none";
            }
        }

        resizeCanvas();
    }

    export const resizeCanvas=()=> {
        var canvases = document.querySelectorAll('canvas');
        canvases.forEach(function(canvas) {
            canvas.style.width = '100%';
        });
    }

    export function addClass(elem, cls) {
        elem.classList.add(cls);
    }

    export function removeClass(elem, cls) {
        elem.classList.remove(cls);
    }

    export const delay = ms => new Promise(res => setTimeout(res, ms));

    export const emptyCache = () => {
        if('caches' in window){
            caches.keys().then((names) => {
                // Delete all the cache files
                names.forEach(name => {
                    caches.delete(name);
                })
            });

            // Makes sure the page reloads. Changes are only visible after you refresh.
            window.location.reload(true);
        }
    }
    
    export function groupByKey(xs, key) {
        return xs.reduce(function(rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    }

    export function replaceAll(str, replaceChar, replaceWith) {
        return str.split(replaceChar).join(replaceWith);
    }

    export function arraysMatch(a, b) {
        return a.toString()===b.toString();
    }

    export function randomInt(min, max) { // min and max included 
        return Math.floor(Math.random() * (max - min + 1) + min)
    }

    export function sortArray(arr, propIndex) {
        const comp = comparator(propIndex);
        return arr.sort(comp);
    }

    export function comparator(idx) {
        if(!idx) idx = 0;
        return (a, b) => {
            if (a[idx] < b[idx]) return -1;
            if (a[idx] > b[idx]) return 1;
            return 0;
        }
    }

    export const debounce = (func, timeout = 500) => {
        let timeoutId;
        return (...args) => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                func(...args);
            }, timeout);
        };
    };

    export const pixelsToRem = (px, base=16) => {
        const tempPx = `${px}`.replace('px', '')
      
        return (1 / base) * parseInt(tempPx)
    }

    export const chartProps = () => {        
        const vw = window.innerWidth;

        if(vw <=1024) {
            return ['7rem', vw/150, 1.5, vw/1750];
        }

        if(vw <=1100) {
            return ['8rem', vw/150, 3, vw/1750];
        }

        if(vw <=1280) {
            return ['9rem', vw/150, 3.5, vw/1500];
        }

        if(vw <=1530) {
            return ['12.5rem', vw/150, 4, vw/1500];
        }

        if(vw <=1745) {
            return ['13rem', vw/150, 4.5, vw/1000];
        }
        
        if(vw <=1920) {
            return ['14rem', vw/150, 5, vw/1500];
        }

        if(vw <=2048) {
            return ['14rem', vw/150, 5.5, vw/1500];
        }
        
        if(vw <=2400) {
            return ['16rem', vw/125, 6, vw/1500];
        }

        if(vw <=2560) {
            return ['20rem', vw/125, 6.5, vw/1250];
        }

        if(vw <=2880) {
            return ['24rem', vw/125, 7, vw/1250];
        }
        
        if(vw <=3850) {
            return ['28rem', vw/125, 9, vw/1350];
        }

        if(vw <=5800) {
            return ['40rem', vw/125, 14, vw/1500];
        }

        if(vw <=7652) {
            return ['50rem', vw/125, 18, vw/1500];
        }

        return ['55rem', vw/125, 24, vw/1500]
    }

    export const getBrowserIdentity = () => {
        try {            
            return navigator?.userAgent ?? "navigator cannot be determined";
        }
        catch(ex) {
            return "navigator error"
        }
        
    }

    export const findIndexById = (records, id, PK) => {
        let index = -1;
        for (let i = 0; i < records.length; i++) {
            if (records[i][PK]===id) {
                index = i;
                break;
            }
        }
        return index;
    }


    export const objectToQs = (obj, keys = []) =>
        Object.entries(obj).reduce((pairs, [key, value]) => {
            if(value===null) {
                pairs.push([[...keys, key.toLowerCase()], '']);
            }
            else {
                if (typeof value === 'object')
                    pairs.push(...objectToQs(value ?? '', [...keys, key.toLowerCase()]));
                else
                    pairs.push([[...keys, key.toLowerCase()], encodeURIComponent(value)]);
            }
        
        
            //.includes("world")
          return pairs;
        }, []);

    /*--- End utility functions --*/


    /*--- Start string functions --*/

    export function right(str, chr) {
        const rightStr=str.substr(str.length-chr,str.length);
        return rightStr;
    }

    export function left(str, cnt) {
        return str.substr(0, cnt)
    }

    export function capitalizeString(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    export function numberToWords(number) {
        if(number===0) return;
        if(number.length>=6) return number;

        const groups = [
            // { value: 1e18, symbol: 'quintillion' },
            // { value: 1e15, symbol: 'quadrillion' },
            { value: 1e12, symbol: 'T' },
            { value: 1e9, symbol: 'B' },
            { value: 1e6, symbol: 'M' },
            { value: 1e3, symbol: 'K' }
        ];

        for (let i = 0; i < groups.length; i++) {
            if (number >= groups[i].value) {
                return `$${(number / groups[i].value).toFixed(2).replace(/\.0$/, '') + '' + groups[i].symbol}`;
            }
        }
        return number.toString();
    }

    export function truncateString(str, len) {
        return str.substring(0, len - 1)
    }

    export function uuidv4() {
        return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }

    export const emptyGuid = () => {
        return "00000000-0000-0000-0000-000000000000";
    }

    export function padTrailingZeros(num, totalLength) {
        return String(num).padEnd(totalLength, '0');
    }
    
    function stringToHslColor(str, s, l) {
        var hash = 0;

        for (var i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        
        var h = hash % 360;
        return 'hsl('+h+','+s+'%,'+l+'%)';
    }
    
    export function getHslFromString(str) {
        var s = randomInt(50, 100);
        var l = randomInt(25, 75);
        var textColor = l > 65 ? '#555' : '#fff';
        var bgColor = stringToHslColor(str, s , l);

        return [bgColor, textColor].toString();
    }

    export const concatShardRealmAccountNum = (shard, realm, accountNum) => {
        return `${shard}.${realm}.${accountNum}`;
    }

    export function fieldNameToColumnName(field) {
        var arr = field.split("_");
        if(arr.length===1) {
            arr = field.split(".");
        }

        var colName="";
        arr.forEach((part, idx) => {
            part = part.replace("timestamp","date");

            //part = capitalizeFirstChar(part)
            if(idx > 0) {
                part = " " + part;
            }
            colName += part;        
        });

        return colName;
    }

    export function capitalizeFirstChar(word) {
        if(!word) return;
        const firstLetter = word.charAt(0);
        const firstLetterCap = firstLetter.toUpperCase();
        const remainingLetters = word.slice(1).toLowerCase();
        return firstLetterCap + remainingLetters;
    }

    export const timeframeToText = (tf) => {
        let text;

        switch(tf) {
            case '1':
                text = '24 Hours';
                break;
            case '7':
                text = '7 Days';
                break;
            case '30':
                text = '30 Days';
                break;
            case '365':
                text = '1 Year';
                break;
            default:
                text = tf
        }

        return text;
    }

    export function getDefictPrefix(val) {
        var prefix='';
        if(val < 0) {
            val=val.toString().replace("-","");
            prefix = '(';
        }

        return prefix;
    }

    export function getDefictSuffix(val) {
        var suffix='';
        if(val < 0) {
            suffix = ')'
        }

        return suffix;
    }

    /*--- End string functions --*/

    /*--- Start Memberstack functions --*/

    export const getMsMember = () => {
        return JSON.parse(localStorage.getItem('_ms-mem'));
    }

    export const getMsToken = () => {
        return localStorage.getItem('_ms-mid');
    }

    export const openLoginModal = (openModal, hideModal, navigate, redirect) => {
        let redirectUrl
        
        if(!redirectUrl) {
            redirectUrl = sessionStorage.getItem('__sak:path');
        }        

        openModal({
            type: "LOGIN",
        })
        .then(({ data, type }) => {
            if(!data) {
                hideModal();
                return;
            }         

            hideModal();

            if(!redirect) {
                navigate(redirectUrl ?? data.member.loginRedirect, { state: { isLogin: true, redirect: redirectUrl }, replace: true });
            }                
            else {
                redirect();
            }
        });    
    }


    export const openResetPasswordModal = (openModal, hideModal) => {
        openModal({
            type: "FORGOT_PASSWORD",
        })
        .then(({ data, type }) => {
            if(!data) {
                hideModal();
                return;
            }         

            hideModal();
        });    
    }

    export const openSignupModal = (openModal, hideModal) => {
        openModal({
            type: "SIGNUP",
        })
        .then(({ data, type }) => {
            if(!data) {
                hideModal();
                return;
            }         

            hideModal();
        });    
    }

    /*--- End Memberstack functions --*/

    export function calcTps(response) {
        if(!response.data.blocks) return 0;
        
        const blocks = response.data.blocks[0].count ?? .1;
        const tsTo = response.data.blocks[0].timestamp.to;
        const tsFrom = response.data.blocks[0].timestamp.from
        var tps = Math.round(1 / (tsTo - tsFrom) * blocks) !== undefined ? Math.round(1 / (tsTo - tsFrom) * blocks) : 0;
        
        if(tps===Infinity || !tps) tps = 0;

        return tps;
    }
    
    export const appStateMessage = (msg) => {
        return {message: msg}
    }

    export const verifyPageAccess = (sessionState, navigate) => {
        const member = sessionState.data;
        
        if(member?.permissions?.length > 0 && !member?.verified) {
            navigate("/verify", { state: { }, replace: true });
            return;
        }
        else if (!objectIsEmpty(member) && (!member?.customFields?.uniqueid)) {
            navigate("/unauthorized", { state: { member }, replace: true });
            return;
        }
    }

    export function testMethods() {
        console.clear();
        console.log('getTimestamp(): ' + getTimestamp());
        console.log('getUtcDateMidnight(): ' + getUtcDateMidnight());
        console.log('getUtcDateMidnight(2023,1,1): ' + getUtcDateMidnight(2023,1,1));
        console.log('getUtcDateMidnight(2019,9,16): ' + getUtcDateMidnight(2019,9,16));
        console.log('formatDate("02/29/2024"): ' + formatDate('02/29/2024'));
        console.log('daysToHours(7): ' + daysToHours(7));
        console.log('daysAgoToUtcDate(7): ' + daysAgoToUtcDate(7));
        console.log('daysAgoToUtcDate(1): ' + daysAgoToUtcDate(1));
        console.log('daysAgoToUtcDate(365): ' + daysAgoToUtcDate(365));
        console.log('getUtcCalendarDate(): ' + getUtcCalendarDate());
        console.log('tsToDate("1694044799.000000"): ' + tsToDate('1694044799.000000'));
        console.log('nanoToLocalDate("1722122210178000000"): ' + nanoToLocalDate('1722122210178000000'));
        console.log('getUtcDay(getUtcCalendarDate()): ' + getUtcDay(getUtcCalendarDate()));
        console.log('getUtcMonth(getUtcCalendarDate()): ' + getUtcMonth(getUtcCalendarDate()));
        console.log('getUtcYear(getUtcCalendarDate()): ' + getUtcYear(getUtcCalendarDate()));
        console.log('getUtcDate no ts: ' + getUtcDate());
        console.log('getUtcDate(1) with ts: ' + getUtcDate(1));
        console.log('getUtcDateStr: ' + getUtcDateStr());
        console.log('getUtcEpochDate: ' + getUtcEpochDate());
        console.log('getOperableEpoch: ' + getOperableEpoch());
        console.log('daysDiff(): ' + daysDiff(new Date(new Date().getFullYear(), 0, 1), new Date()))
        console.log('mapFilterOption: ' + mapFilterOption('1'));
        console.log('utcDateToNano("2024-08-27T20:36:18Z"): ' + utcDateToNano("2024-08-27T20:36:18Z"));
        console.log('randomInt: ' + randomInt(1, 1000));
        //console.log('randomHslColor: ' + randomHslColor());
        //console.log('getHslFromString: ' + getHslFromString('Adam'));
    }

    //testMethods();

