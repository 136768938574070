import React from 'react';
import { Button } from 'primereact/button';
import { TXT_FREE_TRIAL_LENGTH } from '../../shared/constants';
import './_plan.scss';

const Plan = (props) => {
    const { plans, selectedPrice, formMode, onPriceClick } = props;

    const prices = plans?.filter(x => x.status==="ACTIVE");
    
    const fauxClick = () => {}

    const showPlans = (        
        prices?.map((price, idx) => {
            const opt = <div 
                key={idx} 
                id={`price-${price.id}`} 
                style={{
                    border: '0px solid purple'
                    , width: '100%'
                    , borderRadius: '.25rem'
                    , justifyContent: 'center'
                    , display: (formMode === 'review' && selectedPrice === price.id) || formMode === 'edit' ? 'flex' : 'none'
                }}>

                <div className={`plan-option 
                    ${selectedPrice === price.id ? 'plan-selected' : ''}
                    
                    //${price.status !== 'ACTIVE' ? 'hidden' : ''}
                    price-${price.interval.type.toLowerCase()}
                    ${price.interval.type==="YEARLY" ? 'ribbon' : ''} ribbon-${price.name}`} 
                    data-ribbon="Popular"
                        // style={{
                        //     borderRadius: '1rem'
                        //     , width: `${100}%`
                        //     , padding: '1rem'
                        //     , margin: '.25rem'
                        // }}
                    style={{width: formMode==='edit' ? '100%' : '50%', borderRadius: '1rem', padding: '1rem', margin: '.25rem'}}
                >                                
                        <div style={{textAlign: 'center', fontSize:'1.5rem', fontWeight:'500', paddingBottom: '.5rem'}}>
                            <span style={{fontSize:'1.5rem', fontWeight:'500'}}>
                                ${ price.interval.type.toLowerCase()==="yearly" ? (price.amount / 12).toString()+' / mo *' : (price.amount).toString()+' / mo' }</span><span>
                            </span>
                        </div>
                         
                        <div style={{textAlign:'center', paddingBottom: '1rem', minWidth: '13rem'}}>
                            <Button
                                type="button" 
                                label={`Select${selectedPrice === price.id ? 'ed' : ' ' + price.name} `}
                                id={price.id} 
                                data-priceid={price.id} 
                                onClick={() => (formMode === 'review') ? fauxClick() : onPriceClick(price.id)}
                                className={`no-fx select-plan${selectedPrice === price.id ? '-selected' : ''} ${formMode==='review' ? 'faux-button' : ''}`}
                                style={{width: formMode==='edit' ? '75%' : '75%'}}
                            />
                        </div>

                        <div style={{textAlign: 'center', paddingBottom: '1rem'}}>
                            <span style={{fontSize:'1rem', fontWeight:'400', display: price.interval.type.toLowerCase()==="yearly" ? 'block' : 'none'}}>* ${price.amount} billed annually</span>
                            <span style={{display: price.interval.type.toLowerCase()==="monthly" ? 'block' : 'none'}}>billed monthly</span>
                        </div>
                </div>
            </div>

            return opt
        })
    );

    return (   
        <div className={`${formMode === 'edit' ? 'pt-3' : ''}`} style={{width: '100%'}}>            
            <div className={`features flex justify-content-center`}>
                <ul>
                    <li className={``}><i className="pi pi-check mr-1 accent" /> Access to real-time Data Services</li>
                    <li className={``}><i className="pi pi-check mr-1 accent" /> Access to Interactive Charting</li>
                    <li className={``}><i className="pi pi-check mr-1 accent" /> Access to the Valuation Model</li>
                    <li className={``}><i className="pi pi-check mr-1 accent" /> Access to the Enlightened Hbarbarian blog</li>
                    <li className={``}><i className="pi pi-check mr-1 accent" /> Access to Staking Rewards Cost Basis</li>                    
                </ul>
            </div>
            <div className="pt-0 pb-3 accent" style={{textAlign: 'center', fontSize: '1.1rem'}}>{ TXT_FREE_TRIAL_LENGTH } Day Free Trial Included</div>
            {/* <div className={`${formMode === 'edit' ? 'pb-3' : 'hidden'}`}>&nbsp;</div> */}
            <div className={`flex pb-2`}>
                { showPlans }
            </div>
            <div style={{width: '100%', lineHeight: '1.333rem'}} className={`${formMode==='edit' ? 'hidden' : 'flex justify-content-center pb-3'}`}>
                Your card will not be charged until the trial period ends. To avoid charges, you can cancel your membership anytime during the trial period.
                For your convenience, your membership will automatically renew unless canceled.
            </div>
        </div>        
    );
  }

  export default Plan