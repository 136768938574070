import React from 'react';
import ReactDOM from 'react-dom/client';
//import reportWebVitals from './reportWebVitals';
import { RouterProvider, Route, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import { MemberstackProvider } from "@memberstack/react";
import memberstackDOM from "@memberstack/dom";
import loadable from '@loadable/component';
import App from './App';
import {NAV_SERVICES, NAV_GOVCOUNCIL, NAV_MODEL, NAV_SUPPORT, NAV_ROADMAP, NAV_PROFILE} from './../src/shared/constants';
//import HashConnectAPIProvider from "./components/hooks/useHashconnect";

import {
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const Dashboard = loadable(() => import('./routes/dashboard/dashboard'));
const Default = loadable(() => import('./routes/default/default'));
const Verify = loadable(() => import('./verify'));
const Verified = loadable(() => import('./verified'));
const Profile = loadable(() => import('./routes/account/profile/profile'));
const NotFound = loadable(() => import('./notfound'));
//const Preview = loadable(() => import('./components/forms/preview'));

const Revenue = loadable(() => import('./routes/charts/revenue'));
const NetworkData = loadable(() => import('./routes/charts/network'));
const StakingEconomics = loadable(() => import('./routes/charts/staking'));
const Defi = loadable(() => import('./routes/charts/defi'));
const HbarDist = loadable(() => import('./routes/charts/distribution'));

const Analytics = loadable(() => import('./routes/analytics/analytics'));
const Roadmap = loadable(() => import('./routes/roadmap/roadmap'));
const Admin = loadable(() => import('./routes/admin/admin'));
const Blog = loadable(() => import('./routes/blog/blog'));
const Support = loadable(() => import('./routes/support/support'));
const GC = loadable(() => import('./routes/gc/gc'));
const Services = loadable(() => import('./routes/account/services/services'));
const Login = loadable(() => import('./login'));
const Unsubscribe = loadable(() => import('./unsubscribe'));
const Canceled = loadable(() => import('./canceled'));
const Unauthorized = loadable(() => import('./../src/routes/unauthorized'));

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false
        }
    }
});

let MS_PUBLIC_KEY;

if(process.env.NODE_ENV ==='development') {
    //MS_PUBLIC_KEY = process.env.REACT_APP_MSTACK_TEST_PUBLIC_KEY;
    MS_PUBLIC_KEY = process.env.REACT_APP_MSTACK_PUBLIC_KEY;
}
else {
    if(window.location.host.includes('hashpoint.netlify.app')) {
        MS_PUBLIC_KEY = process.env.REACT_APP_MSTACK_PUBLIC_KEY;
    }
    else {
        MS_PUBLIC_KEY = process.env.REACT_APP_MSTACK_PUBLIC_KEY;
    }
}

const config = { publicKey: MS_PUBLIC_KEY };
const memberstack = memberstackDOM.init({
    publicKey: config.publicKey,
    sessionDurationDays: 1
});

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route 
            path="/" 
            element={<App urlParams={ urlParams } memberstack={ memberstack } />} >

            <Route index element={<Default title="Default" />} />
            <Route path="home" element={<Default title="Default" />} />
            <Route path="dashboard"element={<Dashboard title="Dashboard" />} />
            <Route path="profile" element={<Profile title={NAV_PROFILE} memberstack={ memberstack } />} />            
            <Route path="networkdata" element={<NetworkData title={`Network Data`} />} />
            <Route path="revenue" element={<Revenue title={`Revenue`}  />} />
            <Route path="defionhedera" element={<Defi title={`Defi On Hedera`}  />} />
            <Route path="stakingeconomics" element={<StakingEconomics title={`Staking Economics`}  />} />
            <Route path="hbardistribution" element={<HbarDist title={`HBAR Distribution`}  />} />
            <Route path="valuationmodel" element={<Analytics title={NAV_MODEL} />} />
            <Route path="insights" element={<Blog title="The Enlightened Hbarbarian" />} />
            <Route path="admin" element={<Admin title="Admin" />} />
            <Route path="governingcouncil" element={<GC title={NAV_GOVCOUNCIL} />} />
            <Route path="roadmap" element={<Roadmap title={NAV_ROADMAP} />} />
            <Route path="notfound" element={<NotFound />} />
            <Route path="support" element={<Support title={NAV_SUPPORT} />} />
            <Route path="services" element={<Services title={NAV_SERVICES} />} />
            <Route path="login" element={ <Login />}/>
            <Route path="unsubscribe" element={ <Unsubscribe />} />
            <Route path="verified" element={ <Verified />} />
            <Route path="verify" element={ <Verify />}/>
            <Route path="cancel" element={ <Canceled />}/>
            {/* <Route path="preview" element={ <Preview />}/> */}
            <Route path="unauthorized" element={ <Unauthorized />}/>
            <Route path="*" element={ <NotFound />} />
        </Route>
    )
  );

  const Entrypoint = () => {
    return (
        root.render(
            <MemberstackProvider config={config}>
                <React.StrictMode>
                    {/* <HashConnectAPIProvider debug={false}> */}
                        <QueryClientProvider client={queryClient}>
                            <ReactQueryDevtools initialIsOpen={true} />
                            <RouterProvider router={router} />
                        </QueryClientProvider>
                    {/* </HashConnectAPIProvider> */}
                </React.StrictMode>
            </MemberstackProvider>
        )
    )
  }

  Entrypoint();

//if ('serviceWorker' in navigator) {
    //// Register a service worker hosted at the root of the
    // site using the default scope.
    //navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/service-worker.js`).then(
    //  registration => {
    //    console.log('Service worker registration succeeded:', registration);
    //  },
    //  /*catch*/ error => {
    //    console.error(`Service worker registration failed: ${error}`);
    //  }
    //);
//} else {
//    console.error('Service workers are not supported.');
//}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
