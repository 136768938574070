import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import CreateMembershipForm from '../forms/createMembership';
import FinishMembershipForm from '../forms/finishMembership';
import { useSession } from '../stores/useSession';
import { delay, getBreakpoints } from '../../shared/fn';
import './_buttons.scss'

const SignupButton = (props) => {
    const [disabled] = useState(props.disabled);
    const [showCreateDialog, setShowCreateDialog] = useState(false);
    const [state, actions] = useSession();
    //const [xxx, setXxx] = useState();

    const handleCreateButtonClick = (e, isOnFreePlan) => {
        setShowCreateDialog(true);
    }

    const handleDialogClose = (e) => { 
        setShowCreateDialog(false);
    }

    useEffect(() => {
        const setButtonVisibility = async() => {            
            await delay(1000);
            
            const createAcctBtn = document.getElementsByName('create-account');
            
            if(!state.sessionId) {
                createAcctBtn && createAcctBtn[0]?.classList.remove('hidden');
                createAcctBtn && createAcctBtn[1]?.classList.remove('hidden');
            } 
        }
    
        setButtonVisibility();
    });

    return (  
        <div>
            <Button
                id={`CreateAccountButton`}
                name={`create-account`}
                visible={true}
                label={`${ props.label }`}
                onClick={(e) => !disabled ? handleCreateButtonClick(e) : void(0)}
                className={`p-button-outlined create-account hidden no-fx ${props.renderAsLink ? 'button-as-link' : ''}`}
                tooltip={``}
                disabled={disabled}
            />

            <Dialog
                className={`create-account-dialog`}
                visible={showCreateDialog && !disabled}
                onHide={() => setShowCreateDialog(false)}
                modal
                breakpoints={getBreakpoints()}
                header={`${ props.label }`}
                style={{ width: '45vw', height:'auto' }}
                draggable={false}
                resizable={false}
            >
                <div>
                    {
                        !state.data?.auth 
                            ? 
                            <div className = {`create-account-form`}>       
                                <CreateMembershipForm 
                                    closeDialog={ handleDialogClose }                                
                                />
                            </div> 
                            : 
                            <div className = {`finish-account-form`}> 
                                <FinishMembershipForm 
                                    closeDialog={ handleDialogClose }
                                />
                            </div>
                    }
                    </div>          
            </Dialog>   
        </div>
    );
  }
  
  export default SignupButton